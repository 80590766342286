import { PageLayout } from "../../components/Layout/PageLayout"
import { CustomLink } from "../../components/Links/CustomLink"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { PageSeo } from "../../utilities/seo/PageSeo"

const videos = [{
    youtuberName: "Northernlion",
    src: "https://www.youtube.com/embed/soF3kTi5_wU"
}, {
    youtuberName: "JackSucksAtGeography",
    src: "https://www.youtube.com/embed/cFAZc4qoFV0"
}, {
    youtuberName: "xQcOW",
    src: "https://www.youtube.com/embed/gLXw9tW1fpc"
}, {
    youtuberName: "TMG",
    src: "https://www.youtube.com/embed/GsaHXFJDmaw"
}, {
    youtuberName: "DrewDurnil",
    src: "https://www.youtube.com/embed/I-fXv5up1MM"
}, {
    youtuberName: "JellyYT",
    src: "https://www.youtube.com/embed/wgA_BB8cW7U"
}]

/**
 * Hall of fame page.
 */
export const HallOfFame: React.FC = () => <PageLayout>
    <PageSeo title="Hall of fame" description="Check out our Hall of Fame and see the top scorers from around the world! These players have put their guessing skills to the test and earned a spot in our prestigious leaderboard. Will you be the next champion?" canonicalLink={routes.misc.halloffame} />
    <Title>Hall of fame</Title>
    <p className="text-gray mb-5 lg:mb-10">Check out who played moreorless! <CustomLink to={routes.misc.feedback}>Contact us</CustomLink> to get featured here.</p>

    {videos.map(video => <div key={video.youtuberName} className="w-full lg:w-1/2 mb-5 lg:mb-10">
        <h3 className="text-lg md:text-xl font-semibold mb-2">{video.youtuberName}</h3>
        <div className="relative pb-[56.25%]">
            <iframe className="absolute top-0 left-0 w-full h-full" src={video.src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    </div>)}
</PageLayout>
