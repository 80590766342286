type ManualStepProps = {
    /** Name of image to display without svg. */
    imgkey: string
    /** Title of the step. */
    title: string
    /** Description of the step. */
    description: string
    /** Whether the text should be on the left or right. True when it should show left and image right and vice versa. */
    hasTextStart: boolean
}

/**
 * Displays a step in the manual.
 */
export const ManualStep: React.FC<ManualStepProps> = ({ imgkey, title, description, hasTextStart = false }) => {
    return <div className="lg:flex items-center mb-10">
        <img src={`/img/manual/${imgkey}.png`} alt="gamemodes" className="lg:w-1/2 object-cover mr-5 mb-5 lg:mb-0" />
        <div className={`lg:w-1/2 ${hasTextStart ? "-order-1" : "order-1"}`}>
            <h3 className="text-lg lg:text-2xl font-semibold">{title}</h3>
            <p className="text-gray text-sm md:text-base">{description}</p>
        </div>
    </div>
}