import React, { useEffect } from "react";
import { hasAds } from "../../services/ads";

export const AdBlockIds = {
  mol2_ingame_playing: "9905120383",
  mol2_games_overview: "2647531176",
  mol2_common: "8550588374",
  mol2_ingame_endscreen: "1275088414",
} as const;

export type AdBlockId = (typeof AdBlockIds)[keyof typeof AdBlockIds];

const adsenseData: { [key in AdBlockId]: React.ReactElement } = {
  [AdBlockIds.mol2_ingame_playing]: (
    <ins
      className="adsbygoogle inb"
      data-ad-client="ca-pub-2622767131138725"
      data-ad-slot="9905120383"
    ></ins>
  ),
  [AdBlockIds.mol2_games_overview]: (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key="+31+qn-3c-6z+rp"
      data-ad-client="ca-pub-2622767131138725"
      data-ad-slot="2647531176"
    ></ins>
  ),
  [AdBlockIds.mol2_common]: (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-2622767131138725"
      data-ad-slot="8550588374"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  ),
  [AdBlockIds.mol2_ingame_endscreen]: (
    <ins
      className="adsbygoogle"
      style={{ display: "block", width: "336px", height: "280px" }}
      data-ad-client="ca-pub-2622767131138725"
      data-ad-slot="1275088414"
    ></ins>
  ),
};

export type AdsenseProps = {
  type: AdBlockId;
};

declare let adsbygoogle: any; // eslint-disable-line @typescript-eslint/no-unused-vars
export const Adsense: React.FC<AdsenseProps> = ({ type }) => {
  useEffect(() => {
    if (!hasAds()) return;

    try {
      // Google adsense
      (adsbygoogle = (window as any).adsbygoogle || []).push({});
    } catch (error) {
      //console.error("Failed to load mol2", error)
    }
  }, []);

  if (!hasAds()) {
    return (
      <div className={`bg-red`}>
        <p>Adsense is disabled!</p>
      </div>
    );
  }

  return adsenseData[type];
};
