import { PageLayout } from "../../components/Layout/PageLayout"
import { CustomLink } from "../../components/Links/CustomLink"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { PageSeo } from "../../utilities/seo/PageSeo"

/**
 * Privacy Policy Page.
 */
export const PrivacyPolicy: React.FC = () => <PageLayout>
    <PageSeo title="Privacy Policy" description="Learn how we collect, use, and disclose your data when you use our website or services. We value your privacy and are committed to providing transparency in our data practices." canonicalLink={routes.legal.privacy} />
    <Title>Privacy</Title>
    <p className="mb-4 md:mb-6">We are MoreOrLess ("we", "our", "us"). We’re committed to protecting and respecting your privacy. If you have questions about your personal information please contact us.</p>

    <h4 className="text-2xl font-semibold md:mb-2">What information we hold about you</h4>
    <p className="mb-4 md:mb-6">We may collect non-personally identifiable information about you in the course of your interaction with our site. This information may include technical information about the browser or type of device you're using. This information will be used purely for the purposes of analytics and tracking the number of visitors to our site.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Keeping your data secure</h4>
    <p className="mb-4 md:mb-6">We are committed to ensuring that any information you provide to us is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable measures and procedures to safeguard and secure the information that we collect.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Cookie policy</h4>
    <p className="mb-4 md:mb-6">Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enable the site's or service provider's systems to recognize your browser and capture and remember certain information. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
    <p className="mb-2 md:mb-4">We use cookies to:</p>
    <ul className="list-disc ml-10 mb-4 md:mb-6">
        <li className="mb-2 md:mb-3">Understand and save user's preferences for future visits.</li>
        <li className="mb-2 md:mb-3">Keep track of advertisements.</li>
        <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</li>
    </ul>
    <p className="mb-4 md:mb-6">If you disable cookies, some features may be disabled but it mustn't affect your user experience in any material way.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Third-party links</h4>
    <p className="mb-4 md:mb-6">Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Third-party cookies</h4>
    <p className="mb-4 md:mb-6">We use cookies from the following third parties:</p>
    <ul className="list-disc ml-10 mb-4 md:mb-6">
        <li className="mb-2 md:mb-3">
            <h6 className="text-lg font-semibold">Google Analytics</h6>
            <p>We use <CustomLink to="https://marketingplatform.google.com/about/analytics/" external>Google Analytics</CustomLink> to collect statistical information regarding how the website is used. This information is not personally identifiable.</p>
        </li>
        <li className="mb-2 md:mb-3">
            <h6 className="text-lg font-semibold">Google Adsense</h6>
            <p>We use <CustomLink to="https://www.google.com/adsense/" external>Google AdSense</CustomLink> to display ads on some of our pages. Google AdSense may use user data. You can review Google's privacy policy regarding advertising <CustomLink external to="https://www.google.com/policies/privacy/ads/">here</CustomLink>. That page also provides information on how to manage what information is collected and how to opt out of collection.</p>
        </li>
        <li className="mb-2 md:mb-3">
            <h6 className="text-lg font-semibold">Google Tag Manager</h6>
            <p>We use <CustomLink to="https://support.google.com/tagmanager/answer/6102821" external>Google Tag Manager</CustomLink> to manage google services. Google Tag Manager may use user data.</p>
        </li>
        <li className="mb-2 md:mb-3">
            <h6 className="text-lg font-semibold">Google Fonts</h6>
            <p>We use <CustomLink to="https://fonts.google.com/" external>Google Fonts</CustomLink> to have a customized font. Google Fonts may use user data. You can review Google's privacy policy <CustomLink external to="https://policies.google.com/privacy">here</CustomLink>. That page also provides information on how to manage what information is collected and how to opt out of collection.</p>
        </li>
        <li className="mb-2 md:mb-3">
            <h6 className="text-lg font-semibold">Fontawesome</h6>
            <p>We use <CustomLink to="https://fontawesome.com/" external>Fontawesome</CustomLink> to use icons. Fontawesome may use user data.You can review Fontawesome's privacy policy <CustomLink external to="https://fontawesome.com/privacy">here</CustomLink>. That page also provides information on how to manage what information is collected and how to opt out of collection.</p>
        </li>
    </ul>

    <h4 className="text-2xl font-semibold md:mb-2">How to disable cookies</h4>
    <p className="mb-4 md:mb-6">Most modern browsers allow you to control your cookie settings for all websites that you browse. You can disable cookie deployment completely by editing your browser settings, however in doing this you may be limiting the functionality that is displayed on our website. To learn how to disable cookies on your preferred browser we recommend reading this <CustomLink external to="https://support.google.com/accounts/bin/answer.py?hl=en&answer=61416">advice posted by Google.</CustomLink></p>
    <p className="mb-4 md:mb-6">You can also disable Google Analytics on all websites by downloading the <CustomLink external to="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on.</CustomLink></p>

    <h4 className="text-2xl font-semibold md:mb-2">Rights</h4>
    <p className="mb-4 md:mb-6">You have a right to access the personal data we hold about you or obtain a copy of it. To do so please contact us. If you believe that the information we hold for you is incomplete or inaccurate, you may contact us to ask us to complete or correct that information. You also have the right to request the erasure of your personal data. Please contact us if you would like us to remove your personal data.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Acceptance of this policy</h4>
    <p className="mb-4 md:mb-6">Continued use of our site signifies your acceptance of this policy. If you do not accept the policy then please do not use this site. When registering we will further request your explicit acceptance of the privacy policy.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Changes to this policy</h4>
    <p className="mb-4 md:mb-6">We may make changes to this policy at any time. You may be asked to review and re-accept the information in this policy if it changes in the future.</p>
</PageLayout>
