import { useQuery } from "@tanstack/react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchGames } from "services/api";
import { GameType } from "../../types/game";
import { FeaturedTag } from "../../types/tag";
import { AdBlockIds, Adsense } from "../Adsense/Adsense";
import { InternalServerError } from "../Errors/InternalServerError";

type GamesProps = {
  /** Current feature tag. */
  featuredTag: FeaturedTag;
};

type AdsenseCard = {
  isAdsense: true;
};

/**
 * Displays all games as cards, sorts games, handles fetching, loading and error from games.
 */
export const Games: React.FC<GamesProps> = ({ featuredTag }) => {
  // Local States
  const [mounted, setMounted] = useState(false);
  const [sortedGames, setSortedGames] = useState<GameType[]>([]);

  // Queries
  const { isInitialLoading, isError, isSuccess, data } = useQuery<
    GameType[],
    any
  >(["games"], fetchGames);

  // For animation when mount
  useLayoutEffect(() => {
    window.requestAnimationFrame(() => setMounted(true));
  }, []);

  // Sort game array
  useEffect(() => {
    if (!data) return;

    // Sort games to with or without order defined
    const gamesWithOrder = data.filter((game) => game.order !== undefined);
    const gamesWithoutOrder = data.filter((game) => game.order === undefined);
    const orderedGames = gamesWithOrder.sort((a, b) => a.order! - b.order!);

    // Merge ordered and unordered games
    const allGames = [...orderedGames, ...gamesWithoutOrder];

    setSortedGames(allGames);
  }, [data]);

  // Skeleton Loading State
  if (isInitialLoading)
    return (
      <div
        className={`${
          mounted ? "transition-opacity duration-700 opacity-100" : "opacity-0"
        } grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:flex 2xl:flex-wrap gap-4`}
      >
        {[...Array(8)].map((_, index) => (
          <div key={index} className="animate-pulse bg-slate-900 2xl:w-96">
            <div className="h-72 bg-slate-700"></div>
            <div className="grid grid-cols-3 bg-slate-800 p-4 md:p-5">
              <div className="h-3 bg-slate-700 rounded-full col-span-2 mb-4"></div>
              <div className="h-2 bg-slate-700 rounded col-span-4 mb-2"></div>
              <div className="h-2 bg-slate-700 rounded col-span-2"></div>
            </div>
          </div>
        ))}
      </div>
    );

  // Error state fallback
  if (isError) return <InternalServerError showLogo={false} />;

  const gamesWithTag = sortedGames.filter((game) =>
    game.tags.includes(featuredTag.tag)
  );

  console.log(`${gamesWithTag.length} games with tag ${featuredTag.tag}`);

  const randomIndex = Math.floor(Math.random() * (gamesWithTag.length + 1));

  const gamesWithAdsense: (GameType | AdsenseCard)[] = [...gamesWithTag];

  gamesWithAdsense.splice(randomIndex, 0, {
    isAdsense: true,
  } as unknown as GameType);

  return (
    <div
      className={`${
        mounted ? "transition-opacity duration-300 opacity-100" : "opacity-0"
      } grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:flex 2xl:flex-wrap gap-4`}
    >
      {isSuccess &&
        gamesWithAdsense.map((game) =>
          "isAdsense" in game ? (
            <div
              id="ads_container"
              key={`${featuredTag.tag}_adsense`}
              className="bg-white text-blue card-hover-border 2xl:w-96"
            >
              <Adsense type={AdBlockIds.mol2_games_overview} />
            </div>
          ) : (
            <Link
              key={game.slug}
              to={`/game/${game.slug}`}
              className="bg-white text-blue card-hover-border 2xl:w-96"
            >
              {game.badge && (
                <p className="absolute bg-red text-white text-sm drop-shadow-[0_4px_3px_rgba(0,0,0,.25)] mt-6 -m-2 px-5 py-2">
                  {game.badge}
                </p>
              )}
              <div className="bg-slate-700 w-full aspect-square">
                <img
                  className="w-full"
                  src={`${process.env.REACT_APP_API_URL}/img/${game.image}_512.jpg`}
                  alt={`${game.title} Higher or Lower Game Thumbnail`}
                />
              </div>
              <div className="p-4 md:p-5">
                <h3 className="text-xl text-red font-semibold italic uppercase pb-2">
                  {game.title}
                </h3>
                <p
                  style={{
                    WebkitLineClamp: 2,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  }}
                  className="overflow-hidden text-blue"
                  title={game.description}
                >
                  {game.description}
                </p>
              </div>
            </Link>
          )
        )}
    </div>
  );
};
