export const gamesOverviewSeo: {
    [key: string]: {
        title: string,
        description: string
    }
} = {
    "trends": {
        title: "Trending Game Modes",
        description: "Browse our higher or lower online guessing games and discover trending game modes. You can play games with the topics YouTube, Twitch, People and World."
    },
    "new": {
        title: "New Game Modes",
        description: "Browse our higher or lower online guessing games and discover new game modes. You can play games with the topics YouTube, Twitch, People and World."
    },
    "youtube": {
        title: "Youtube Guessing Games",
        description: "Browse our higher or lower online guessing games and discover youtube game modes. You can play games with Youtube Views, Youtube Subscribers, Pewdiepie Video Views, League of Legend Youtuber, Fortnite Youtuber, Beauty Youtuber."
    },
    "twitch": {
        title: "Twitch Guessing Games",
        description: "Browse our higher or lower online guessing games and discover twitch game modes. You can play games with Twitch Subscriber."
    },
    "people": {
        title: "People Guessing Games",
        description: "Browse our higher or lower online guessing games and discover people game modes. You can play games with Net Worth of People, Salary Range."
    },
    "world": {
        title: "Our famous World Guessing Games",
        description: "Browse our higher or lower online guessing games and discover world game modes. You can play games with Player Market Values Fifa World Cup, Country Size, Design History, Lagest Cities in the United States, Rainfall in Europe, Population."
    },
    "movies": {
        title: "Movie Guessing Games",
        description: "Browse our higher or lower online guessing games and discover movies game modes. You can play games with IMDb Movie Ratings, IMDb Series and Shows Ratings, Cost of Movies, Worldwide Gross of Movies, Actor Worth to a Movie, Tomatometer Score."
    },
    "social media": {
        title: "Social Media Guessing Games",
        description: "Browse our higher or lower online guessing games and discover social media game modes. You can play games with Youtube Views, Youtuber Subscriber, Most Instagram Followers, Most Visited Websites."
    },
    "games": {
        title: "Games Guessing Games",
        description: "Browse our higher or lower online guessing games and discover gaming game modes. You can play games with League of Legends Champion Prices, League of Legends Skins, League of Legends Win Rates, CS:GO Weapon Prices, Animal Crossing: New Horizons Fishes and Insects."
    },
    "spotify": {
        title: "Spotify Guessing Games",
        description: "Browse our higher or lower online guessing games and discover Spotify game modes. You can play games with Spotify Monthly Listeners, Spotify Artists Follower Count, Spotify Top Tracks."
    },
    "weights": {
        title: "What Weighs More? Guessing Game",
        description: "Play our fun higher or lower online guessing game and guess which items, cars or people are heavier or lighter."
    }
}