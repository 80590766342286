import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ContextType, ReactElement, useContext, useEffect, useState } from "react"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import 'react-horizontal-scrolling-menu/dist/styles.css'
import useDrag from "../../hooks/useDrag"
import { TabProps } from "./Tab"
import { TabTag } from "./TabTag"

type TabsProps = {
    /** Tabs to display */
    children: ReactElement<TabProps>[]
    /** Index of the selected tab */
    selectedTabIndex: number
}

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

/**
 * Tabs container, wrap around tab component
 * @example <caption>Basic example</caption>
 * <TabsContainer>
 *   <Tab title="tab 1">
 *     Tab Content 1
 *   </Tab>
 *   <Tab title="tab 2">
 *     Tab Content 2
 *   </Tab>
 * </TabsContainer>
 */
export const Tabs: React.FC<TabsProps> = ({ children, selectedTabIndex }) => {
    /**
     * Handle mouse move
     */
    const { dragStart, dragStop, dragMove } = useDrag()
    const handleDrag = ({ scrollContainer }: ScrollVisibilityApiType) => (event: React.MouseEvent) => dragMove(event, (posDiff) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff
        }
    })

    /**
     * Updates localstorage mol_current_tab_index
     * @param tag current tag tab
     */
    const handleTabClick = () => (): void => {
        localStorage.setItem("mol_current_tab_index", selectedTabIndex.toString())
    }

    return (
        <div onMouseLeave={dragStop}>
            <ScrollMenu onInit={({ scrollToItem, getItemById }) => {
                scrollToItem(getItemById(selectedTabIndex.toString()), 'smooth', 'center') // Scrolls to position of selectedTab... Problem: It tries to put element in center
            }} wrapperClassName="relative whitespace-nowrap" LeftArrow={LeftArrow} RightArrow={RightArrow} onMouseDown={() => dragStart} onMouseUp={() => dragStop} onMouseMove={handleDrag}>
                {children.map((children, index) => (
                    <TabTag key={children.props.featuredTag.tag} onClick={handleTabClick()} to={children.props.featuredTag.tag !== "trends" ? `/games/${children.props.featuredTag.tag.replaceAll(' ', '-')}` : "/games"} itemId={index.toString()} selected={selectedTabIndex === index} item={children} />
                ))}
            </ScrollMenu>
            {children[selectedTabIndex]}
        </div>
    )
}

const LeftArrow: React.FC = () => {
    const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } = useContext(VisibilityContext)
    const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible))

    useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isFirstItemVisible)
        }
    }, [isFirstItemVisible, visibleElements])

    return <button className={`flex items-center h-full absolute bg-gradient-to-r from-blue via-blue z-10 pr-5 ${disabled ? "hidden" : ""}`} onClick={() => scrollPrev()}>
        <FontAwesomeIcon icon={faChevronLeft} className="p-2" />
    </button>
}

const RightArrow: React.FC = () => {
    const { isLastItemVisible, scrollNext, visibleElements } = useContext(VisibilityContext)
    const [disabled, setDisabled] = useState(!visibleElements.length && isLastItemVisible)

    useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isLastItemVisible)
        }
    }, [isLastItemVisible, visibleElements])

    return <button className={`flex items-center h-full absolute right-0 bg-gradient-to-l from-blue via-blue z-10 pl-5 ${disabled ? "hidden" : ""}`} onClick={() => scrollNext()}>
        <FontAwesomeIcon icon={faChevronRight} className="p-2" />
    </button>
}

