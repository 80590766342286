import { Link } from 'react-router-dom'
// import de from '../assets/img/flags/de.svg'
// import en from '../assets/img/flags/en.svg'
import Logo from '../../assets/img/Logo.svg'
import { Chip } from '../../components/Chip/Chip'
import { Footer } from '../../components/Footer/Footer'
import { routes } from '../../routes'
import { hasViewedPatchNotes } from '../../services/version'

export const Home: React.FC = () => {
    // const languages = [
    //     {
    //         title: "German",
    //         icon: de
    //     },
    //     {
    //         title: "English",
    //         icon: en
    //     }
    // ]

    return (<>
        <div className="container min-h-screen flex flex-col justify-between items-center">
            {/* Logo */}
            <div className="mt-auto">
                <img src={Logo} className="mt-10 mb-16 md:mb-24" alt="Logo" />
            </div>

            {/* Menu Links */}
            <ul className="text-gray text-center font-semibold mb-auto">
                <li className="mb-5 md:mb-8">
                    <Link to={routes.games.overview} className="text-5xl md:text-7xl text-white uppercase hover:opacity-90">Play</Link>
                </li>
                <li className="mb-3">
                    <Link to={routes.profile} className="text-lg md:text-xl hover:opacity-80">Career Profile</Link>
                </li>
                {/* <li className="mb-3">
                    <Link to={routes.misc.language} className="text-lg md:text-xl hover:opacity-80">Sprache</Link>
                    <div className="flex justify-center mt-2">
                        {languages.map(language => (
                            <button key={language.title} onClick={() => console.log(language.title)} className={`text-xl mr-2`} title={`Change language to ${language.title}`}>
                                <img src={language.icon} className="w-8" alt={`${language.title} flag`} />
                            </button>
                        ))}
                    </div>
                </li> */}
                <li className="mb-3">
                    <Link to={routes.misc.halloffame} className="text-lg md:text-xl hover:opacity-80">Hall of Fame</Link>
                </li>
                <li className="mb-3">
                    <Link to={routes.misc.manual} className="text-lg md:text-xl hover:opacity-80">Manual</Link>
                </li>
                <li className="mb-3">
                    <Link to={routes.misc.patchnotes} className="text-lg md:text-xl hover:opacity-80">Patch Notes {!hasViewedPatchNotes() && <span className='ml-2'>
                        <Chip color="red">New</Chip>
                    </span>}</Link>
                </li>
            </ul>

            {/* Misc Links */}
            <Footer />
        </div>
    </>)
}