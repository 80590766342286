import { Trophy } from "../types/profile"

export const findTrophy = (maxScore: number, score: number): Trophy | null => {
    let winnerTrophies = []
    if (maxScore < 22) {
        winnerTrophies = [
            Math.floor(maxScore / 3), // Bronze
            Math.floor(maxScore / 3 * 1.5), // Silber
            Math.floor(maxScore / 3 * 2), // Gold
            maxScore // Champion
        ]
    }
    else {
        winnerTrophies = [
            5, // Bronze
            10, // Silber
            20, // Gold
            maxScore // Champion
        ]
    }

    // Champion
    if (score === winnerTrophies[3])
        return "champion"

    // All others
    const trophies: Trophy[] = ["bronze", "silver", "gold", "champion"]
    for (let i = 0; i < winnerTrophies.length - 1; i++) {
        if ((winnerTrophies[i] <= score) && (score < winnerTrophies[i + 1]))
            return trophies[i]
    }

    // None
    return null
}