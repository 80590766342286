import { Color } from "../../types/color"

type ChipProps = {
    /** Color of chip in Tailwind color name. E.g.: `indigo`, `red` */
    color?: Color
    /** Content of chip */
    children: React.ReactNode
}

/**
 * Display a chip with text.
 */
export const Chip: React.FC<ChipProps> = ({ children, color = "indigo" }) => <span className={`bg-${color} bg-opacity-20 text-${color} text-sm rounded-full px-4 py-1`}>{children}</span>
