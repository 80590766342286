import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { trophiesToImage } from "../../pages/Profile/Profile"
import { msToHMS } from "../../services/tools"
import { Trophy } from "../../types/profile"

type ProfileGameCardProps = {
    /** Slug of the game. */
    slug: string
    /** Preview Thumbnail image of the game. */
    image: string
    /** Title of the game. */
    title: string
    /** How long the game has been played in milliseconds. */
    timePlayedInMillis: number
    /** How many times the game has been played. */
    playedCount: number
    /** Highscore of the game. */
    highscore: number
    /** Total score of the game. */
    totalScore: number
    /** Highest trophy achieved in the game. */
    trophy: Trophy | null
}

// Constants
const IMAGE_SIZE = 128

/**
 * Card to show game modes on profile carrer page.
 */
export const ProfileGameCard: React.FC<ProfileGameCardProps> = ({ slug, image, title, timePlayedInMillis: timePlayed, playedCount, highscore, totalScore, trophy }) => {
    return <>
        <div className="flex bg-gray bg-opacity-10 p-3 md:p-5 mb-4 group">
            {/* Image with play hover */}
            <Link to={`/game/${slug}`} className="grid w-26 h-26 md:w-auto md:h-auto mr-4">
                <img src={`${process.env.REACT_APP_API_URL}/img/${image}_${IMAGE_SIZE}.jpg`} alt={title} className="row-start-1 row-end-1 col-start-1 col-end-1 object-cover h-full md:h-auto" />
                <div className="row-start-1 row-end-1 col-start-1 col-end-1 h-full w-full flex items-center justify-center bg-blue bg-opacity-40 transition-opacity opacity-0 group-hover:opacity-100">
                    <FontAwesomeIcon icon={faPlay} className="text-3xl" />
                </div>
            </Link>

            {/* Game info */}
            <div className="flex flex-col justify-center items-start">
                <Link to={`/game/${slug}`} className="text-sm md:text-base text-gray">{title}</Link>
                <h5 className="text-lg md:text-2xl font-semibold mb-1">{highscore} Highscore {trophy && <img alt={`${trophy} icon`} title={`You earned a ${trophy} trophy!`} src={trophiesToImage[trophy]} className="inline w-10" />}</h5>
                <p className="text-sm md:text-base">You played {playedCount} times in {msToHMS(timePlayed)} and your total score is {totalScore}.</p>
            </div>
        </div>
    </>
}