import { Duration } from "luxon"
import { GameData } from "../types/game"

/**
 * Format milliseconds to hh:mm:ss
 * @param ms - Milliseconds
 * @returns a string in the format hh:mm:ss
 */
export const msToHMS = (ms: number) => {
    const duration = Duration.fromMillis(ms)
    return duration.toFormat("hh:mm:ss")
}

/**
 * Shuffles array in place.
 * @param array - The array to shuffle
 * @returns the shuffled array
 */
export const shuffleGameArray = (array: GameData): GameData => {
    array = [...array] as GameData
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
    }
    return array
}

/**
 * Set a short sleep timeout for animations.
 * @param ms - Milliseconds 
 */
export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

/**
 * Format number to locale string.
 */
export const formatter = new Intl.NumberFormat()

