
export type SummaryCardProps = {
    /** Name of image to display without svg. */
    imageKey: string
    /** Value to display. */
    value: string | number
    /** Description of the card for the value. */
    description: string
}

/**
 * Displays a card for a profile statistic, with an image icon, the statistic value, and a short keyword to describe the value.
 */
export const SummaryCard: React.FC<SummaryCardProps> = ({ imageKey, value, description }) => {
    return <div className="flex flex-col items-center justify-center bg-gray bg-opacity-10 py-4 sm:py-8 md:py-16">
        <img className="w-8 md:w-16 mb-2 md:mb-4" src={`/img/profile/${imageKey}.svg`} alt={`${description} Icon`} />
        <span className="text-2xl md:text-5xl font-semibold">{value}</span>
        <p className="text-sm md:text-base text-gray">{description}</p>
    </div>
}