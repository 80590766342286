import { Avatars, Highscore, mlUser, mlUserAvatar, mlUserStats, Trophy, UserProfile } from "../types/profile"
import { getRandomAvatar } from "./userProfile"

//const cookies = '_ga=GA1.2.1714242248.1645284278; ml_user_stats=[["most-instagram-followers",1,0,0,0,0],["rich-people",1,0,0,0,0],["youtubers",2,1,2,1,0],["lol-champion-skins",3,1,18,1,0],["imdb-ratings",16,3,362,11,0],["rainfall-in-europe",1,0,0,0,0],["cost-of-movies-with-inflation",1,0,0,0,0],["imdb-tv-series-shows-ratings",1,0,0,0,0],["design-history",7,1,306,2,0],["country-size",20,3,6653,15,0],["fortnite-youtubers",2,2,7,2,0],["pewdiepie-video-views",1,0,0,0,0],["actor-worth-to-a-movie",1,0,0,0,0],["german-youtubers",2,0,2,0,0],["salary-range",1,0,0,0,0],["animal-crossing-new-horizons-fishes-and-insects",1,0,0,0,0],["league-of-legends-youtubers",1,0,0,0,0],["german-league-of-legends-youtubers",1,0,0,0,0],["beauty-youtubers",1,0,0,0,0],["twitch",1,0,0,0,0],["lol-champion-prices",1,0,0,0,0],["lol-champion-win-rates",1,0,0,0,0],["spotify-monthly-listeners",1,0,0,0,0],["cost-of-movies",1,0,0,0,0],["worldwide-gross-of-movies",1,0,0,0,0],["tomatometer-score",1,0,0,0,0],["rotten-tomatoes-critics",1,0,0,0,0],["largest-cities-in-the-united-states",1,0,0,0,0]]; ml_user={"name":"George","avatar":{"name":"Sick","src":"img/game/icons/avatars/sick.svg"},"registerDate":"2022-03-22T21:19:22.705Z","totalscore":32,"totaltimesplayed":73,"totaltimeplayed":7350,"averagescore":0.4,"alltrophies":0}; _gid=GA1.2.749410747.1653390176; XSRF-TOKEN=eyJpdiI6InlEMDR5amNDU29kN1BcLzV5aEJyQVpnPT0iLCJ2YWx1ZSI6InFQNngrNHptRVhnZTg0ZGZoUXp1Q3ZFeXMzMFwvakVYU2hRcWdMNTV2T3RMbzBpUThvclRHQ1l2Yjh2aGhhOEhCUjRIUU1reHBBWUUrVDVkZjllRWg3XC9CSTlyUUduTkNiU1puTkVHSWxwcDEzbnNaSFpRM0kwRDhcL2Z4U0VhRGE2IiwibWFjIjoiMmJmNWE4Y2VjZTQ3NmJlOTZjZDdjY2U2MzNlZjJhMjEzNGEyMzA5NWRhNmYzYTVjOTM0MDY3ZTZhOGFmMjhiNSJ9'
const cookies = document.cookie

export const getCookieData = (): [mlUser, mlUserStats] | false => {
    const mlUserCookie = getCookieByName("ml_user")
    const mlUserStatsCookie = getCookieByName("ml_user_stats")

    // There is no cookie
    if (!mlUserCookie || !mlUserStatsCookie)
        return false

    // try block catchs wrong string while parsing
    try {
        const mlUser: mlUser = JSON.parse(mlUserCookie)
        const mlUserStats: mlUserStats = JSON.parse(mlUserStatsCookie)
        return [mlUser, mlUserStats]
    } catch (error) {
        return false
    }
}

export const migrateProfile = (mlUser: mlUser, mlUserStats: mlUserStats): UserProfile => {
    // We have valid v2 Data, go migrate them
    const newProfileUser: UserProfile = {
        name: mlUser.name,
        avatar: getAvatarSlugFromV2(mlUser.avatar),
        registerDate: mlUser.registerDate,
        globalTotalScore: mlUser.totalscore,
        globalPlayedCount: mlUser.totaltimesplayed,
        globalTimePlayedInMillis: mlUser.totaltimeplayed * 1000, // v2 version is in seconds
        highscores: []
    }

    newProfileUser.highscores = mlUserStats.reduce<Highscore[]>((acc, mlUserStat) => {
        // Do not merge if score is zero
        if (mlUserStat[2] < 1)
            return acc

        const highscore: Highscore = {
            slug: mlUserStat[0],
            highscore: mlUserStat[2],
            playedCount: mlUserStat[1],
            timePlayedInMillis: mlUserStat[3] * 1000,
            totalScore: mlUserStat[4],
            trophy: getTrophyFromV2(mlUserStat[5])
        }
        acc.push(highscore)
        return acc
    }, [])

    return newProfileUser
}

// TODO: Improve algo
export const getCookieByName = (name: string): string | void => {
    const value = `; ${cookies}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop()!.split(';').shift()
}

export const getAvatarSlugFromV2 = (avatarV2: mlUserAvatar): Avatars => {
    const imageKey = avatarV2.src.match(/avatars\/(.*).svg/)![1] as Avatars
    return imageKey ?? getRandomAvatar()
}

export const getTrophyFromV2 = (trophyIndex: number): Trophy | null => {
    const trophies: (Trophy | null)[] = [null, "bronze", "silver", "gold", "champion"]
    return trophies[trophyIndex]
}