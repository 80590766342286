import { Trophy } from "../types/profile";

export function sendScoreToAnalytics(slug: string, score: number, timePlayedInMillis: number, trophy: Trophy | null) {
    // @ts-ignore
    gtag('event', 'game_finished', {
        'event_category': 'Game',
        'event_label': 'User Score',
        'value': score,
        'game': slug,
        'trophy': trophy,
        'time_played_in_millis': timePlayedInMillis
    });
}
