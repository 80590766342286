import {
  faCheck,
  faChevronLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AdBlockIds, Adsense } from "../../components/Adsense/Adsense";
import { Button } from "../../components/Buttons/Button";
import { CountUpNumber } from "../../components/Count/CountUpNumber";
import { CountUpString } from "../../components/Count/CountUpString";
import { InternalServerError } from "../../components/Errors/InternalServerError";
import { NotFound } from "../../components/Errors/NotFound";
import { IngameBlock } from "../../components/Games/IngameBlock";
import { Loading } from "../../components/Loading/Loading";
import { BackButton } from "../../components/Navbar/BackButton";
import { routes } from "../../routes";
import { fetchSingleGame } from "../../services/api";
import { findTrophy } from "../../services/findTrophy";
import { formatter, shuffleGameArray, sleep } from "../../services/tools";
import { getProfile, newGameScore } from "../../services/userProfile";
import { GameData } from "../../types/game";
import { Trophy } from "../../types/profile";
import { GameSeo } from "../../utilities/seo/GameSeo";
import { trophiesToImage } from "../Profile/Profile";

// Game config
const randomizeOrder = true;
const failOnWrongAnswer = true;
const shouldUpdateHighscoreInGame = false;

// Animation config
const animAnimatingDuration = 1000;
const animNextDuration = 1000;
const animEndingGameDuration = 1000;

const trophiesText: { [key in Trophy | "none"]: string } = {
  none: "You can do this better. We both know!",
  bronze: "Bronze Medal",
  silver: "Silver Medal, not bad.",
  gold: "Gold Medal, impressive!",
  champion: "Wow! You guessed everything correct.",
};

export const Game: React.FC = () => {
  // Get slug from url
  const { slug } = useParams();
  // Load game data
  const { isInitialLoading, isError, data } = useQuery([`game_${slug}`], () =>
    fetchSingleGame(slug!)
  );

  // Local variables
  const gameStartTimeRef = useRef<DateTime | null>(null);
  const [gameStatus, setGameStatus] = useState<
    "init" | "playing" | "animating" | "next" | "ending" | "end" | "win"
  >("init");
  const [gameData, setGameData] = useState<GameData | null>(null);

  const [logicScore, setLogicScore] = useState<number>(0); // Score internal to calculate results
  const [displayScore, setDisplayScore] = useState<number>(0); // Score to display in UI
  const [highscore, setHighscore] = useState<number>(0); // Best score so far

  const [hideAdsense, setHideAdsense] = useState<boolean>(false);

  const startGame = () => {
    if (!data) {
      console.error("no data");
      return;
    }
    console.log("startGame");

    // check game data
    if (data.game.data === undefined || data.game.data.length < 2) {
      console.error("game data is probably wrong");
      return;
    }

    // get game data
    const gameData = randomizeOrder
      ? shuffleGameArray(data.game.data)
      : data.game.data;

    gameStartTimeRef.current = DateTime.now();

    setHighscore(() => {
      const profile = getProfile();
      const highscore = profile.highscores.find(
        (highscore) => highscore.slug === slug
      );

      if (!highscore) return 0;
      return highscore.highscore;
    });
    setLogicScore(0);
    setDisplayScore(0);
    setGameData(gameData);
    setGameStatus("playing");
  };

  const clickMore = () => {
    console.log("clickMore");
    pickItem("more");
  };

  const clickLess = () => {
    console.log("clickLess");
    pickItem("less");
  };

  const pickItem = async (decision: "more" | "less") => {
    if (!gameData) {
      console.error("no gameData");
      return;
    }

    // Do not allow picks when game is not in game status
    if (gameStatus !== "playing") {
      console.warn("Game is over or not ready yet!");
      return;
    }

    setGameStatus("animating");
    await sleep(animAnimatingDuration);

    const isLess = gameData[logicScore][1] > gameData[logicScore + 1][1];
    const isMore = gameData[logicScore][1] < gameData[logicScore + 1][1];

    // Guess is wrong
    if ((isMore && decision === "less") || (isLess && decision === "more")) {
      if (failOnWrongAnswer) {
        await endingGame("ending");
      }

      console.log("guess is wrong");
      return;
    }

    // Guess is correct
    setDisplayScore((displayScore) => displayScore + 1);
    if (shouldUpdateHighscoreInGame && logicScore + 1 > highscore)
      setHighscore(logicScore + 1);

    if (logicScore >= gameData.length - 2) {
      // end of game
      console.log("end of game");
      await endingGame("win");
      return;
    }

    setGameStatus("next");
    await sleep(animNextDuration);
    setGameStatus("playing");
    setLogicScore((score) => score + 1);
  };
  // TODO: ref game state from ending to lost
  const endingGame = async (finalGameStatus: "ending" | "win") => {
    if (!data || !gameStartTimeRef.current) {
      console.error("Fatal error");
      return;
    }
    const timePlayedInMillis = Math.abs(
      gameStartTimeRef.current.diffNow().toMillis()
    );
    setGameStatus(finalGameStatus);
    const reachedScore =
      finalGameStatus === "win" ? logicScore + 1 : logicScore;
    newGameScore(
      data.slug,
      reachedScore,
      timePlayedInMillis,
      findTrophy(data.game.data.length - 1, reachedScore)
    );
    await sleep(animEndingGameDuration);
    setGameStatus("end");
  };

  const restartGame = () => {
    console.log("restartGame");
    startGame();
    setHideAdsense(true);
    setTimeout(() => setHideAdsense(false));
  };

  const startGameCallback = useCallback(startGame, [data, slug]);

  // init game
  useEffect(() => {
    // TODO: Find out reason for:  This is there to prevent game from erroing while init
    if (!data) return;
    startGameCallback();
  }, [data, startGameCallback]);

  // Check if we are loading
  if (isInitialLoading) {
    return <Loading />;
  }

  // Check if there is data
  if (!data) {
    return <NotFound />;
  }

  // Check if we have game data ready (if data is there and gameData not => Game is loading!)
  if (!gameData) {
    return <Loading />;
  }

  // Error Fallback
  if (isError) return <InternalServerError />;

  /*********  At this poin game is loaded and has data ********/

  // Structured data
  const breadcrumbStructuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": `${process.env.REACT_APP_BASE_URL}/games`,
          name: "Games",
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": `${process.env.REACT_APP_BASE_URL}/game/${data.slug}`,
          name: data.title,
        },
      },
    ],
  };

  const gameStructuredData = {
    "@context": "https://schema.org",
    "@type": "Game",
    audience: {
      "@type": "PeopleAudience",
      suggestedMinAge: "8",
    },
    description: `${data.description} - Play the next generation of Higher or Lower!`,
    url: `${process.env.REACT_APP_BASE_URL}/game/${data.slug}`,
    gameLocation: `${process.env.REACT_APP_BASE_URL}/game/${data.slug}`,
    image: `${process.env.REACT_APP_API_URL}/img/${data.image}_512.jpg`,
  };

  // Check if game is text based
  const isTextBasedGame = gameData[0][4] !== undefined;

  // Check if 3rd item is there (to prerender)
  const hasPreview = logicScore < gameData.length - 2;

  const getItemData = (item: 0 | 1 | 2) => ({
    display: gameData[logicScore + item][0],
    value: !isTextBasedGame
      ? gameData[logicScore + item][1]
      : gameData[logicScore + item][4]!,
    image: gameData[logicScore + item][2],
    description: gameData[logicScore + item][3],
  });

  // get the items. Order: item | itemNext | itemPreview
  const item = getItemData(0);
  const itemNext = getItemData(1);
  const itemPreview = hasPreview && getItemData(2);

  // game strings
  const valueTitle = data.game.strings.valueTitle;
  const verb = data.game.strings.verb;
  const valuePrefix = data.game.strings.valuePrefix ?? "";
  const valueSuffix = data.game.strings.valueSuffix ?? "";
  const buttonMore = data.game.strings.buttonMore ?? "More";
  const buttonLess = data.game.strings.buttonLess ?? "Less";

  const shouldDisplayValue =
    gameStatus === "animating" ||
    gameStatus === "next" ||
    gameStatus === "ending" ||
    gameStatus === "win";
  const shouldDisplayButtons = gameStatus === "playing";

  const isInGame =
    gameStatus === "playing" ||
    gameStatus === "animating" ||
    gameStatus === "next" ||
    gameStatus === "ending" ||
    gameStatus === "win";
  const isGameEnd = gameStatus === "end";

  const statusElement =
    gameStatus === "playing" || gameStatus === "animating" ? (
      "vs"
    ) : gameStatus === "next" || gameStatus === "win" ? (
      <FontAwesomeIcon icon={faCheck} />
    ) : gameStatus === "ending" ? (
      <FontAwesomeIcon icon={faTimes} />
    ) : (
      "error"
    );
  const trophy = findTrophy(data.game.data.length - 1, displayScore);

  return (
    <>
      <GameSeo game={data} />
      {/** Ad Block */}
      <div className="fixed flex justify-center items-start md:items-end z-20 bottom-0 bg-blue md:bg-transparent overflow-hidden h-[90px] w-full lg:h-32 2xl:h-36">
        {!hideAdsense && <Adsense type={AdBlockIds.mol2_ingame_playing} />}
      </div>
      {/** Game */}
      {isInGame ? (
        <div className="overflow-hidden inset-0 fixed">
          {/** Game UI */}
          <div
            style={
              gameStatus === "next"
                ? { transitionDuration: `${animNextDuration}ms` }
                : {}
            }
            className={`h-[calc(100%_-_90px)] md:h-full w-full fixed flex flex-col md:flex-row ${
              gameStatus === "next"
                ? `translate-x-0 md:-translate-x-1/2 -translate-y-1/2 md:translate-y-0 transition-transform ease-[ease-in-out]`
                : ""
            }`}
          >
            <IngameBlock
              description={item.description}
              image={item.image}
              display={item.display}
              verb={verb}
              shouldDisplayValue
              value={`${valuePrefix}${
                typeof item.value === "number"
                  ? formatter.format(item.value)
                  : item.value
              }${valueSuffix}`}
              valueTitle={valueTitle}
            />
            <IngameBlock
              description={itemNext.description}
              image={itemNext.image}
              display={itemNext.display}
              verb={verb}
              shouldDisplayValue={shouldDisplayValue}
              shouldDisplayButtons={shouldDisplayButtons}
              value={
                <>
                  {valuePrefix}
                  {typeof itemNext.value === "number" ? (
                    <CountUpNumber
                      finalValue={itemNext.value}
                      animationDuration={animAnimatingDuration}
                    />
                  ) : (
                    <CountUpString
                      finalValue={itemNext.value}
                      animationDuration={animAnimatingDuration}
                    />
                  )}
                  {valueSuffix}
                </>
              }
              valueTitle={valueTitle}
              buttonLess={buttonLess}
              buttonMore={buttonMore}
              buttonLessOnClick={clickLess}
              buttonMoreOnClick={clickMore}
            />
            {hasPreview && itemPreview && (
              <IngameBlock
                description={itemPreview.description}
                image={itemPreview.image}
                display={itemPreview.display}
                verb={verb}
                shouldDisplayButtons
                valueTitle={valueTitle}
                buttonLess={buttonLess}
                buttonMore={buttonMore}
              />
            )}
          </div>
          {/** Back to gameoverview: Foreground top clickable */}
          <div className="flex fixed z-30 inset-0 text-sm md:text-base mt-3 md:mt-2 h-12 ml-3 mr-2">
            <BackButton defaultHref={routes.games.overview} />
          </div>
          {/** VS, check, x: Foreground not clickable */}
          <div className="fixed z-20 inset-0 pointer-events-none flex items-center justify-center h-[calc(100%_-_90px)] md:h-full">
            <div
              className={`rounded-full w-12 h-12 md:w-24 md:h-24 flex items-center justify-center transition-colors ${
                gameStatus === "next" || gameStatus === "win"
                  ? "bg-green-600"
                  : gameStatus === "ending"
                  ? "bg-red-600"
                  : "bg-blue"
              }`}
            >
              <p className="text-2xl md:text-3xl font-semibold">
                {statusElement}
              </p>
            </div>
          </div>
          {/** Highscore and Score: Background not clickable */}
          <div className="fixed flex flex-col md:flex-row items-end md:items-stretch z-10 inset-0 pointer-events-none mt-3 md:mt-0 mr-4 md:mr-0">
            <div className="flex justify-end md:w-1/2 mt-0 md:mt-4">
              <div className="flex md:flex-col items-center md:items-end">
                <p className="text-xl font-semibold order-1 md:-order-1 ml-2 md:ml-0">
                  {highscore}
                </p>
                <p className="text-sm md:text-base text-gray">Highscore</p>
              </div>
            </div>
            <div
              className={`md:border-r-[2px] border-white transition-opacity mx-4 ${
                gameStatus === "next" ? "opacity-0" : "opacity-1"
              }`}
            />
            <div className="md:w-1/2 mt-0 md:mt-4">
              <div className="flex md:flex-col items-center md:items-start">
                <p className="text-xl font-semibold order-1 md:-order-1 ml-2 md:ml-0">
                  {displayScore}
                </p>
                <p className="text-sm md:text-base text-gray">Score</p>
              </div>
            </div>
          </div>
        </div>
      ) : isGameEnd ? (
        <div className="h-[calc(100%_-_90px)] w-full fixed flex flex-col items-center justify-center px-3">
          <div className="flex justify-between mb-auto w-full">
            <Link className="text-gray mt-4" to={routes.games.overview}>
              <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
              Games
            </Link>
            <div className="flex md:hidden items-center mt-2">
              <p className="text-sm md:text-base text-gray mr-2">
                Join our discord!
              </p>
              <a
                className="flex items-center justify-center bg-[#5865F2] w-10 h-10 rounded-full py-3 "
                href="https://discord.gg/bHvUnKSga2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/discord-logo-symbol.svg"
                  alt="discord logo"
                  className="w-5"
                />
              </a>
            </div>
          </div>
          <div className="flex flex-col items-center mb-10 md:mb-20 w-full">
            {trophy ? (
              <img
                alt={`${trophy} icon`}
                title={`You earned a ${trophy} trophy!`}
                src={trophiesToImage[trophy]}
                className="w-20 md:w-24 mb-3 md:mb-6"
              />
            ) : (
              <div className="container flex justify-center mb-4">
                <Adsense type={AdBlockIds.mol2_ingame_endscreen} />
              </div>
            )}
            <h2 className="text-5xl md:text-7xl font-semibold mb-2">
              {displayScore}
            </h2>
            <p className="text-center mb-3 md:mb-8">
              {!trophy ? trophiesText["none"] : trophiesText[trophy]}
            </p>
            <Button kind="secondary" onClick={restartGame} className="mb-4">
              Try again
            </Button>
            <Link
              to={routes.games.overview}
              className="text-sm md:text-base text-gray mb-3"
            >
              Choose Gamemode
            </Link>
            <Link
              to={routes.profile}
              className="text-sm md:text-base text-gray"
            >
              Career Profile
            </Link>
          </div>
          <div className="md:flex flex-col items-center mb-auto">
            <p className="hidden md:block text-sm md:text-base text-gray text-center mb-2">
              Follow for updates and share your highscore.
            </p>
            <a
              className="hidden md:flex items-center justify-center bg-[#5865F2] w-48 py-3 mb-2"
              href="https://discord.gg/bHvUnKSga2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/img/discord-logo.svg"
                alt="discord logo"
                className="w-32"
              />
            </a>
            <Link to={routes.misc.feedback}>
              Something is wrong? Share Feedback!
            </Link>
          </div>
        </div>
      ) : (
        <p>Something went wrong...</p>
      )}
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbStructuredData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(gameStructuredData)}
      </script>
    </>
  );
};
