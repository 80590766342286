import { GameType } from "../../types/game"
import { baseTitleSuffix, Seo } from "./Seo"

type GameSeoProps = {
    game: GameType
}
const gameThumbnailSize = "1080"

export const GameSeo: React.FC<GameSeoProps> = ({ game }) => <Seo
    description={`${game.description} - Play the next generation of Higher or Lower!`}
    keywords={game.tags.join(", ")}
    canonicalLink={`/game/${game.slug}`}
    title={`${game.seoTitle ?? game.title}${baseTitleSuffix}`}

    ogImage={`${process.env.REACT_APP_API_URL}/img/${game.image}_${gameThumbnailSize}.jpg`}
    ogImageWidth={gameThumbnailSize}
    ogImageHeight={gameThumbnailSize}

    twitterImage={`${process.env.REACT_APP_API_URL}/img/${game.image}_${gameThumbnailSize}.jpg`}
/>