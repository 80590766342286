/**
 * Display loading screen with an loading animation.
 */
export const Loading: React.FC = () => {
    return <div className="h-screen flex flex-col justify-center items-center">
        <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold">Loading</h2>
            <div className="dot-flashing ml-5"></div>
        </div>
    </div>
}