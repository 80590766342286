import React from 'react'
import { FeaturedTag } from '../../types/tag'

export type TabProps = {
    /** The featured tag to assign the content to the right tab. */
    featuredTag: FeaturedTag
    /** The content of the tab. */
    children: React.ReactNode
}

/**
 * Tab children (of tabs component), is used as container for tab content
 * @props title
 * @example <caption>Basic example</caption>
 * <Tabs>
 *   <Tab title="tab 1">
 *     Tab Content 1
 *   </Tab>
 *   <Tab title="tab 2">
 *     Tab Content 2
 *   </Tab>
 * </Tabs>
 */
export const Tab: React.FC<TabProps> = ({ children, featuredTag, ...props }) => {
    return <div {...props} className="mt-3">{children}</div>
}