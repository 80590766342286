import { useEffect, useState } from "react"

type CountUpStringProps = {
    /** String Value to count up to. */
    finalValue: string
    /** Duration of animation in milliseconds. */
    animationDuration?: number
}

/**
 * Display a string that counts up to a final value with an animation. This only works for strings for numbers use <CountUpNumber />.
 */
export const CountUpString: React.FC<CountUpStringProps> = ({ animationDuration = 1000, finalValue }) => {
    const frameDuration = 1000 / 30
    const totalFrames = Math.round(animationDuration / frameDuration)
    const easeOutQuad = (time: number) => time * (2 - time)
    const stringLength = finalValue.length

    const [string, setString] = useState(createRandomString(stringLength))

    useEffect(() => {
        async function animateCount() {
            let frame = 0
            let count = 0
            const countTo = stringLength
            const counter = setInterval(() => {
                frame++
                const progress = easeOutQuad(frame / totalFrames)
                const currentCount = Math.round(countTo * progress)

                if (count !== currentCount) {
                    count = currentCount
                    setString(`${finalValue.slice(0, count)}${createRandomString(countTo - count)}`)
                }

                if (frame === totalFrames) {
                    clearInterval(counter)
                    setString(finalValue)
                }
            }, frameDuration)
        }
        animateCount()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>{string}</>
}

export function createRandomString(length: number) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}
