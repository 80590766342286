export const routes = {
    home: "/",
    games: {
        overview: "/games",
        show: "/game/:slug", // caution: this is used in link, you have to change this url manually
        tabs: "/games/:selectedTab"
    },
    profile: "/career-profile",
    settings: "/settings",
    misc: {
        overview: "/misc",
        manual: "/manual",
        language: "/options/language",
        feedback: "/feedback",
        credits: "/credits",
        halloffame: "/hall-of-fame",
        patchnotes: "/patchnotes"
    },
    legal: {
        privacy: "/misc/privacy",
        tos: "/misc/tos"
    }
}