import { Avatars, Trophy, UserProfile } from "../types/profile"
import { sendScoreToAnalytics } from "./ga4"
import { getCookieData, migrateProfile } from "./migrateProfile"

const localStorageKey = "mol_user_profile"
const names = ["Zoe", "Michael", "Steve", "Luis", "Arlien", "Julian", "Kathi", "Chris", "Tommy"]
export const avatars = ["smile", "sweat", "vampire", "sleepy", "sick", "selfie", "robot", "popcorn", "pain", "music", "heart", "confused", "cheering", "bored", "birthday-boy", "angry", "3d-glasses"] as const

export const getProfile = (): UserProfile => {
    const userProfile = localStorage.getItem(localStorageKey)

    if (!userProfile)
        return createNewUserAndSave()

    return JSON.parse(userProfile)
}

export const createNewUserAndSave = (): UserProfile => {
    // Check if there is user from v2
    const cookieDataFromV2 = getCookieData()

    let user: UserProfile
    if (cookieDataFromV2) {
        // There is old user data. Let's migrate them!
        console.log("We found old data. Migrating them...")
        user = migrateProfile(cookieDataFromV2[0], cookieDataFromV2[1])
    } else {
        // Create new user when there is no old data
        user = {
            name: getRandomUserName(),
            avatar: getRandomAvatar(),
            registerDate: new Date().toISOString(),
            globalTotalScore: 0,
            globalPlayedCount: 0,
            globalTimePlayedInMillis: 0,
            highscores: []
        }
    }

    saveProfile(user)
    return user
}

export const newGameScore = (slug: string, score: number, timePlayedInMillis: number, trophy: Trophy | null) => {

    sendScoreToAnalytics(slug, score, timePlayedInMillis, trophy)

    // Do not save score when score is zero
    if (score < 1)
        return

    const userProfile = getProfile()
    const gameInProfile = userProfile.highscores.find(highscore => highscore.slug === slug)

    // Update highscore list
    if (gameInProfile) {
        // Update highscore and trophy when there is new highscore
        if (gameInProfile.highscore < score) {
            gameInProfile.highscore = score
            gameInProfile.trophy = trophy
        }

        // Update rest each time
        gameInProfile.playedCount++
        gameInProfile.timePlayedInMillis += timePlayedInMillis
        gameInProfile.totalScore += score
    } else {
        const newGameInProfile = {
            slug,
            highscore: score,
            playedCount: 1,
            timePlayedInMillis: timePlayedInMillis,
            totalScore: score,
            trophy
        }

        userProfile.highscores.push(newGameInProfile)
    }

    // Update global stats
    userProfile.globalTotalScore += score
    userProfile.globalPlayedCount++
    userProfile.globalTimePlayedInMillis += timePlayedInMillis
    saveProfile(userProfile)
}

export const saveProfile = (userProfile: UserProfile): void => {
    localStorage.setItem(localStorageKey, JSON.stringify(userProfile))
}

export const getRandomUserName = (): string => {
    return names[Math.floor(Math.random() * names.length)]
}

export const getRandomAvatar = (): Avatars => {
    return avatars[Math.floor(Math.random() * avatars.length)]
}

export const setUsername = (name: string) => {
    const userProfile = getProfile()

    userProfile.name = name

    saveProfile(userProfile)
}

export const setAvatar = (avatar: Avatars) => {
    const userProfile = getProfile()

    userProfile.avatar = avatar

    saveProfile(userProfile)
}

