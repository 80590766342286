import { routes } from "routes";
import { PageSeo } from "utilities/seo/PageSeo";
import { PageLayout } from "../../components/Layout/PageLayout";
import { ManualStep } from "../../components/Manual/ManualStep";
import { Title } from "../../components/Navbar/Title";

const manualSteps = [
  {
    imgKey: "step1",
    title: "Step 1 - Pick Your Game",
    description:
      "Select the game you want to play. Not sure which one? Try a trending game for starters!",
  },
  {
    imgKey: "step2",
    title: "Step 2 - Understand Your Screen",
    description:
      "Your screen is split into two. On the left, you'll see items with their values shown. On the right, you have two choices to pick from. Your current score and the highest score you've achieved are displayed at the top.",
  },
  {
    imgKey: "step3",
    title: "Step 3 - Playing the Game",
    description:
      "Decide if the next item's value is higher or lower than the current one. Make the right guess, and you'll see new items to continue playing. A wrong guess, and it's game over.",
  },
  {
    imgKey: "step4",
    title: "Step 4 - Winning Tips",
    description:
      "Earn trophies by hitting specific scores. Collect all the items on our list to grab a special trophy!",
  },
];

/**
 * Manual page.
 */
export const Manual: React.FC = () => (
  <PageLayout>
    <PageSeo
      title="Manual"
      description="Learn how to play moreorless. Guess whether the next item has a higher or lower number and watch your score increase. With each correct guess, the item moves to the left and a new challenge awaits. How high can you score before making a wrong guess? Play now and find out!"
      canonicalLink={routes.misc.manual}
    />
    <Title>Manual</Title>
    {manualSteps.map((manualStep, index) => (
      <ManualStep
        key={manualStep.title}
        imgkey={manualStep.imgKey}
        title={manualStep.title}
        description={manualStep.description}
        hasTextStart={index % 2 === 0}
      />
    ))}
  </PageLayout>
);
