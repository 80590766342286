import { faSpotify, faTwitch, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faArrowTrendUp, faFilm, faGamepad, faGlobeEurope, faHashtag, faPeopleGroup, faPlus, faScaleUnbalanced, faUser } from "@fortawesome/free-solid-svg-icons"
import { useParams } from "react-router-dom"
import { NotFound } from "../../components/Errors/NotFound"
import { Footer } from "../../components/Footer/Footer"
import { Games } from "../../components/Games/Games"
import { Navbar } from "../../components/Navbar/Navbar"
import { Title } from "../../components/Navbar/Title"
import { Tab } from "../../components/Tabs/Tab"
import { Tabs } from "../../components/Tabs/Tabs"
import { routes } from "../../routes"
import { FeaturedTag } from "../../types/tag"
import { gamesOverviewSeo } from "../../utilities/seo/GamesOverviewSeo"
import { PageSeo } from "../../utilities/seo/PageSeo"

export const featuredTags: FeaturedTag[] = [{
    title: "Trends",
    tag: "trends",
    icon: faArrowTrendUp
},
{
    title: "New",
    tag: "new",
    icon: faPlus
},
{
    title: "World",
    tag: "world",
    icon: faGlobeEurope
},
{
    title: "People",
    tag: "people",
    icon: faPeopleGroup
},
{
    title: "Youtube",
    tag: "youtube",
    icon: faYoutube
},
{
    title: "Movies",
    tag: "movies",
    icon: faFilm
},
{
    title: "Spotify",
    tag: "spotify",
    icon: faSpotify
},
{
    title: "Weights",
    tag: "weights",
    icon: faScaleUnbalanced
},
{
    title: "Social Media",
    tag: "social media",
    icon: faHashtag
},
{
    title: "Games",
    tag: "games",
    icon: faGamepad
},
{
    title: "Twitch",
    tag: "twitch",
    icon: faTwitch
}]

/**
 * List all gamemodes with filter feature tags
 */
export const GamesOverview: React.FC = () => {
    let { selectedTab } = useParams<{ selectedTab: string }>()
    selectedTab = selectedTab?.replaceAll('-', ' ')
    const selectedTabIndex = featuredTags.findIndex((featuredTag) => selectedTab === featuredTag.tag)

    /** Return 404 Error in case: 
     * - selected tab is NOT undefined --> we are not on /games where trends tab is shown
     * - selectedtab index -1 --> we are on a bad slug
     * - selectedtab is trends --> we are on /games/trends, and since we dont want to create duplicate urls and /games show trends tab this should return 404
     */
    if ((selectedTab !== undefined && selectedTabIndex === -1) || (selectedTab === "trends"))
        return <NotFound />

    return (<>
        <PageSeo
            title={gamesOverviewSeo[selectedTab ?? "trends"].title}
            description={gamesOverviewSeo[selectedTab ?? "trends"].description}
            canonicalLink={routes.games.overview}
        />
        <div className="container-fluid mb-5 md:mb-10">
            <Navbar buttonTo={routes.profile} buttonText="Career Profile" buttonIcon={faUser} />
            <Title hasBackButton={false}>Choose game mode</Title>
            <Tabs selectedTabIndex={selectedTab ? selectedTabIndex : 0}>
                {featuredTags.map(featuredTag => (
                    <Tab key={featuredTag.title} featuredTag={featuredTag}>
                        <Games featuredTag={featuredTag} />
                    </Tab>
                ))}
            </Tabs>
        </div>
        <Footer />
    </>)
}