import { baseTitleSuffix, Seo } from "./Seo";
type PageSeoProps = {
    description: string
    keywords?: string
    canonicalLink: string
    title: string
}
export const PageSeo: React.FC<PageSeoProps> = ({ title, description, keywords, canonicalLink }) => <Seo
    description={`${description}`}
    keywords={keywords}
    canonicalLink={canonicalLink}
    title={`${title}${baseTitleSuffix}`}
/>