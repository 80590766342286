import { Route, Routes } from "react-router-dom"
import { NotFound } from "./components/Errors/NotFound"
import { Game } from "./pages/Game/Game"
import { GamesOverview } from "./pages/Game/GamesOverview"
import { Home } from "./pages/Home/Home"
import { PrivacyPolicy } from "./pages/Legal/PrivacyPolicy"
import { TermsOfUse } from "./pages/Legal/TermsOfUse"
import { Credits } from "./pages/Misc/Credits"
import { Feedback } from "./pages/Misc/Feedback"
import { HallOfFame } from "./pages/Misc/HallOfFame"
import { Language } from "./pages/Misc/Language"
import { Manual } from "./pages/Misc/Manual"
import { Misc } from "./pages/Misc/Misc"
import { PatchNotes } from "./pages/Misc/PatchNotes"
import { Profile } from "./pages/Profile/Profile"
import { Settings } from "./pages/Profile/Settings"
import { routes } from "./routes"
import { BaseSeo } from "./utilities/seo/BaseSeo"

export const App = () => {
    return (<>
        <BaseSeo />
        <Routes>
            <Route path={routes.home} element={<Home />} />
            <Route path={routes.games.overview} element={<GamesOverview />} />
            <Route path={routes.games.show} element={<Game />} />
            <Route path={routes.games.tabs} element={<GamesOverview />} />
            <Route path={routes.profile} element={<Profile />} />
            <Route path={routes.settings} element={<Settings />} />
            <Route path={routes.misc.manual} element={<Manual />} />
            <Route path={routes.misc.language} element={<Language />} />
            <Route path={routes.misc.feedback} element={<Feedback />} />
            <Route path={routes.misc.credits} element={<Credits />} />
            <Route path={routes.misc.halloffame} element={<HallOfFame />} />
            <Route path={routes.misc.patchnotes} element={<PatchNotes />} />
            <Route path={routes.misc.overview} element={<Misc />} />
            <Route path={routes.legal.privacy} element={<PrivacyPolicy />} />
            <Route path={routes.legal.tos} element={<TermsOfUse />} />
            <Route path="/*" element={<NotFound />} />
        </Routes>
    </>
    )
}
