import { PageLayout } from "../../components/Layout/PageLayout"
import { CustomLink } from "../../components/Links/CustomLink"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { PageSeo } from "../../utilities/seo/PageSeo"

/**
 * Terms of use page.
 */
export const TermsOfUse: React.FC = () => <PageLayout>
    <PageSeo title="Terms of use" description="Understand our guidelines and expectations with our Terms of Use. Learn about your rights and responsibilities when using our website or services. We are committed to providing a safe and respectful environment for all users." canonicalLink={routes.legal.tos} />
    <Title>Terms of use</Title>
    <p className="mb-4 md:mb-6">The providers ("we", "us", "our") of the service provided by this web site ("Service") are not responsible for any user-generated content and accounts. Content submitted express the views of their author only.</p>
    <p className="mb-4 md:mb-6">This Service is only available to users who are at least 13 years old. If you are younger than this, please do not register for this Service. If you register for this Service, you represent that you are this age or older.</p>
    <p className="mb-4 md:mb-6">All content you submit, upload, or otherwise make available to the Service ("Content") may be reviewed by staff members. All Content you submit or upload may be sent to third-party verification services (including, but not limited to, spam prevention services). Do not submit any Content that you consider to be private or confidential.</p>
    <p className="mb-4 md:mb-6">You agree to not use the Service to submit or link to any Content which is defamatory, abusive, hateful, threatening, spam or spam-like, likely to offend, contains adult or objectionable content, contains personal information of others, risks copyright infringement, encourages unlawful activity, or otherwise violates any laws. You are entirely responsible for the content of, and any harm resulting from, that Content or your conduct.</p>
    <p className="mb-4 md:mb-6">We may remove or modify any Content submitted at any time, with or without cause, with or without notice. Requests for Content to be removed or modified will be undertaken only at our discretion. We may terminate your access to all or any part of the Service at any time, with or without cause, with or without notice.</p>
    <p className="mb-4 md:mb-6">You are granting us with a non-exclusive, permanent, irrevocable, unlimited license to use, publish, or re-publish your Content in connection with the Service. You retain copyright over the Content.</p>
    <p className="mb-4 md:mb-6">These terms may be changed at any time without notice.</p>
    <p className="mb-4 md:mb-6">If you do not agree with these terms, please do not register or use the Service. Use of the Service constitutes acceptance of these terms. If you wish to close your account, please <CustomLink to={routes.misc.feedback}>contact us.</CustomLink></p>

    <h4 className="text-2xl font-semibold md:mb-2">Limitation of liability for internal content</h4>
    <p className="mb-4 md:mb-6">The content of our website has been compiled with meticulous care and to the best of our knowledge. However, we cannot assume any liability for the up-to-dateness, completeness or accuracy of any of the pages.</p>
    <p className="mb-4 md:mb-6">Pursuant to section 7, para. 1 of the TMG (Telemediengesetz – Tele Media Act by German law), we as service providers are liable for our own content on these pages in accordance with general laws. However, pursuant to sections 8 to 10 of the TMG, we as service providers are not under obligation to monitor external information provided or stored on our website. Once we have become aware of a specific infringement of the law, we will immediately remove the content in question. Any liability concerning this matter can only be assumed from the point in time at which the infringement becomes known to us.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Limitation of liability for external links</h4>
    <p className="mb-4 md:mb-6">Our website contains links to the websites of third parties („external links“). As the content of these websites is not under our control, we cannot assume any liability for such external content. In all cases, the provider of information of the linked websites is liable for the content and accuracy of the information provided. At the point in time when the links were placed, no infringements of the law were recognisable to us. As soon as an infringement of the law becomes known to us, we will immediately remove the link in question.</p>

    <h4 className="text-2xl font-semibold md:mb-2">Copyright</h4>
    <p className="mb-4 md:mb-6">The content and works published on this website are governed by the copyright laws of Germany. Any duplication, processing, distribution or any form of utilisation beyond the scope of copyright law shall require the prior written consent of the author or authors in question.</p>
</PageLayout>
