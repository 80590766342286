import { useEffect, useState } from "react"
import { formatter } from "../../services/tools"

type CountUpNumberProps = {
    /** Value to count up to. */
    finalValue: number
    /** Value to start counting from. */
    startValue?: number
    /** Duration of animation in milliseconds. */
    animationDuration?: number
}

/**
 * Display a number that counts up to a final value with an animation. This only works for numbers for strings use <CountUpString />.
 */
export const CountUpNumber: React.FC<CountUpNumberProps> = ({ startValue = 0, animationDuration = 1000, finalValue }) => {
    const frameDuration = 1000 / 60
    const totalFrames = Math.round(animationDuration / frameDuration)
    const easeOutQuad = (time: number) => time * (2 - time)

    const [count, setCount] = useState(startValue)

    useEffect(() => {
        async function animateCount() {
            let frame = 0
            const countTo = finalValue
            const counter = setInterval(() => {
                frame++
                const progress = easeOutQuad(frame / totalFrames)
                const currentCount = Math.round(countTo * progress)

                if (count !== currentCount) {
                    setCount(currentCount)
                }

                if (frame === totalFrames) {
                    clearInterval(counter)
                    setCount(finalValue)
                }
            }, frameDuration)
        }
        animateCount()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <>{formatter.format(count)}</>
}