import email from "../../assets/img/email.png"
import { PageLayout } from "../../components/Layout/PageLayout"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { PageSeo } from "../../utilities/seo/PageSeo"

/**
 * Contact us page.
 */
export const Feedback: React.FC = () => <PageLayout>
    <PageSeo title="Contact us" description="Got a question or feedback about moreorless game? Contact us and let us know! We love to hear from our players and are here to assist you in any way we can. Reach us via discord or email. Thank you for playing moreorless!" canonicalLink={routes.misc.feedback} />
    <div className="mb-5 md:mb-10">
        <Title>Contact us</Title>
        <p className="text-sm md:text-base text-gray mb-2">You are more than welcome to share feedback and request with us.</p>
    </div>

    {/* Discord */}
    <div className="mb-5 md:mb-10">
        <h4 className="text-xl font-semibold mb-2">On our discord</h4>
        <a className="flex items-center justify-center bg-[#5865F2] w-48 py-3" href="https://discord.gg/bHvUnKSga2" target="_blank" rel="noopener noreferrer">
            <img src="/img/discord-logo.svg" alt="discord logo" className="w-32" />
        </a>
    </div>

    {/* Email */}
    <div>
        <h4 className="text-xl font-semibold mb-1">Or with email</h4>
        <img src={email} alt="email" className="w-[185px]" />
    </div>
</PageLayout>
