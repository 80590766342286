import { BaseError } from "./BaseError"

type InternalServerErrorProps = {
    /** When true then the logo will be displayed. */
    showLogo?: boolean
}

/**
 * Error page wrapper for internal server error.
 */
export const InternalServerError: React.FC<InternalServerErrorProps> = ({ showLogo = true }) => <BaseError error="Internal Server Error" title="Something went wrong." description="There is some issue on our site. Pls report this on our discord with a short description what happend and the url where the bug happend." displayDiscord showLogo={showLogo} />