import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation, useNavigate } from "react-router-dom"
import { routes } from "../../routes"

type BackButtonProps = {
    /** The url to navigate back to by default when there is no history. */
    defaultHref?: string
}

/**
 * The back button navigates back in the app's history upon click.
 */
export const BackButton: React.FC<BackButtonProps> = ({ defaultHref = routes.home }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const clickButton = () => {
        // If location.key is default that means you come from outside of the app
        if (location.key === "default") {
            navigate(defaultHref)
        } else {
            navigate(-1)
        }
    }

    return <button className="text-gray" onClick={clickButton}>
        <FontAwesomeIcon icon={faChevronLeft} className="pr-2 md:pr-3" />
        Back
    </button>
}