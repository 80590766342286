import { faCog, faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery } from '@tanstack/react-query'
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { fetchGames } from "services/api"
import { AdBlockIds, Adsense } from "../../components/Adsense/Adsense"
import { Button } from "../../components/Buttons/Button"
import { ProfileGameCard } from "../../components/Cards/ProfileGameCard"
import { SummaryCard, SummaryCardProps } from "../../components/Cards/SummaryCard"
import { InternalServerError } from "../../components/Errors/InternalServerError"
import { NotFound } from "../../components/Errors/NotFound"
import { Footer } from "../../components/Footer/Footer"
import { Loading } from "../../components/Loading/Loading"
import { Navbar } from "../../components/Navbar/Navbar"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { msToHMS } from "../../services/tools"
import { getProfile } from "../../services/userProfile"
import { GameType } from "../../types/game"
import { Trophy, UserProfile } from "../../types/profile"
import { PageSeo } from "../../utilities/seo/PageSeo"

// Trophies
const trophiesAssetBasePath = "/img/trophies"
export const trophiesToImage: { [key in Trophy]: string } = {
    "bronze": `${trophiesAssetBasePath}/bronze.svg`,
    "silver": `${trophiesAssetBasePath}/silver.png`,
    "gold": `${trophiesAssetBasePath}/gold.png`,
    "champion": `${trophiesAssetBasePath}/champion.png`
}

/** 
 * Carrer Profile Page
 */
export const Profile: React.FC = () => {
    const [userProfile] = useState<UserProfile>(getProfile())

    // Fetch for profile game data
    const { data, isInitialLoading, isError } = useQuery<GameType[], any>(['games'], fetchGames)

    // Calculate profile summary card
    // TODO (Zoe-Bot): add unit tests in bug fix #113
    const trophyCount = userProfile.highscores.filter(highscore => highscore.trophy).length
    const avgScore = userProfile.globalPlayedCount !== 0 ? userProfile.globalTotalScore / userProfile.globalPlayedCount : 0

    const profileCardContent: SummaryCardProps[] = [{
        imageKey: "globalPlayedCount",
        value: userProfile.globalPlayedCount,
        description: "Total Times Played"
    }, {
        imageKey: "avgScore",
        value: avgScore !== 0 ? avgScore.toFixed(2) : "0",
        description: "Average Score"
    }, {
        imageKey: "globalTotalScore",
        value: userProfile.globalTotalScore,
        description: "Total Score"
    }, {
        imageKey: "globalTimePlayedInMillis",
        value: msToHMS(userProfile.globalTimePlayedInMillis),
        description: "Total Time Played"
    }, {
        imageKey: "trophyCount",
        value: trophyCount,
        description: "Trophies Earned"
    }]

    /**
     * Scrolls to top of page on mount.
     */
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (isInitialLoading)
        return <Loading />

    if (isError)
        return <InternalServerError />

    if (!data)
        return <NotFound />

    return (<>
        <PageSeo
            title="Career Profile with Highscores and Statistics"
            description="View your Career Profile with highscores and statistics related to our higher or lower game modes."
            canonicalLink={routes.profile}
        />
        <div className="container-fluid mb-5 md:mb-10">
            {/* Navbar */}
            <Navbar buttonTo={routes.games.overview} buttonIcon={faPlay} buttonText="Play" />
            <Title defaultHref={routes.games.overview}>Career Profile</Title>

            {/* Header */}
            <div className="flex flex-col md:flex-row justify-between md:mt-5 mb-8 md:mb-16 ">
                <div className="flex mb-4 md:mb-0">
                    <Link title="Change avatar" to={routes.settings}>
                        <img src={`/img/avatars/${userProfile.avatar}.svg`} alt="profile" className="w-20 h-20 md:w-36 md:h-36 object-cover mr-4" />
                    </Link>
                    <div className="flex flex-col justify-end">
                        <div className="flex items-center md:mb-2">
                            <Link title="Change name" to={routes.settings}>
                                <h2 className="text-2xl md:text-4xl font-semibold mr-2">{userProfile.name}</h2>
                            </Link>
                            <Link className="flex" to={routes.settings}>
                                <FontAwesomeIcon className="text-gray text-opacity-40 text-xl md:text-2xl" icon={faCog} />
                            </Link>
                        </div>
                        <p className="text-sm md:text-base text-gray" title={DateTime.fromISO(userProfile.registerDate).toLocaleString()}>Registered since {DateTime.fromISO(userProfile.registerDate).toRelativeCalendar({ locale: "en" })}</p>
                    </div>
                </div>
                <div className="flex w-full md:w-[50%] h-[90px] md:h-36">
                    <Adsense type={AdBlockIds.mol2_common} />
                </div>
            </div>


            <div className="grid grid-cols-1 xl:grid-cols-2 items-start gap-3">
                {/* Highscore game cards */}
                <div className="order-1 xl:-order-1 xl:mr-10">
                    {userProfile.highscores.sort((a, b) => b.highscore - a.highscore).map(highscore => {
                        const game = data.find(game => game.slug === highscore.slug)
                        if (!game) {
                            console.warn(`Game "${highscore.slug}" not in this version.`)
                            return (null)
                        }
                        return <ProfileGameCard key={highscore.slug} slug={highscore.slug} image={game.image} title={game.title} timePlayedInMillis={highscore.timePlayedInMillis} playedCount={highscore.playedCount} highscore={highscore.highscore} totalScore={highscore.totalScore} trophy={highscore.trophy} />
                    })}
                    <h6 className="text-xl md:text-3xl font-semibold mb-4">Play a game to view your highscores and more statistics here!</h6>
                    <Button to={routes.games.overview} icon={faPlay}>Play</Button>
                </div>

                {/* Summary cards */}
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 gap-4 md:gap-10 mb-1">
                    {profileCardContent.map((profileCard) => <SummaryCard key={profileCard.imageKey} imageKey={profileCard.imageKey} value={profileCard.value} description={profileCard.description} />)}
                </div>
            </div>
        </div>
        <Footer />
    </>)
}