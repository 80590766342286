import { Link } from "react-router-dom"
import { routes } from "../../routes"

export const Footer: React.FC = () => {
    return <ul className="flex flex-col sm:flex-row justify-center text-gray text-center pt-10 pb-5">
        <li className="text-center mb-1 sm:mb-0 sm:mx-2">
            <Link to={routes.misc.feedback} className="hover:opacity-80">Contact</Link>
        </li>
        <li className="mb-1 sm:mb-0 sm:mx-2">
            <Link to={routes.legal.privacy} className="hover:opacity-80">Privacy policy</Link>
        </li>
        <li className="mb-1 sm:mb-0 sm:mx-2">
            <Link to={routes.legal.tos} className="hover:opacity-80">Terms of use</Link>
        </li>
    </ul>
}