import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import Logo from '../../assets/img/Logo.svg'
import { routes } from "../../routes"
import { Button } from "../Buttons/Button"

type ErrorProps = {
    /** Error code or short description of error. */
    error: string | number
    /** Title of the error page. */
    title: string
    /** Description of the error page. */
    description: string
    /** When true then a discord button will be displayed for report the error on discord. */
    displayDiscord?: boolean
    /** When true then the logo will be displayed. */
    showLogo?: boolean
}

/**
 * Base error page with logo, error code, title and description. This is only the base for an error.
 */
export const BaseError: React.FC<ErrorProps> = ({ error, title, description, displayDiscord = false, showLogo = true }) => {
    return <>
        <div className={`container ${showLogo ? "h-screen" : "mt-44"} flex flex-col`}>
            {showLogo && <div className="mt-3 md:mt-5">
                <Link to={routes.home}>
                    <img src={Logo} alt="logo" className="w-40 h-auto md:w-64" />
                </Link>
            </div>}
            <div className="my-auto">
                <div className="flex items-center text-[#5865F2] text-2xl md:text-3xl font-semibold">
                    <p className="pr-2 md:pr-3">{error}</p>
                </div>
                <h1 className="text-2xl md:text-3xl font-semibold md:mb-2">{title}</h1>
                <p className="text-gray text-sm md:text-base md:w-2/3 mb-6">{description}</p>
                {displayDiscord ? <div className="sm:flex items-center">
                    <a className="flex items-center justify-center bg-[#5865F2] sm:w-48 py-2 sm:py-3 sm:mr-4 mb-3 sm:mb-0" href="https://discord.gg/bHvUnKSga2" target="_blank" rel="noopener noreferrer">
                        <img src="/img/discord-logo.svg" alt="discord logo" className="w-28 sm:w-32" />
                    </a>
                    <Link to="/" className="text-white hover:text-gray text-sm md:text-base">
                        <FontAwesomeIcon icon={faArrowLeft} className="pr-2" />
                        Go back home
                    </Link>
                </div> : <Button to="/" kind="primary">Go back home</Button>}
            </div>
        </div>
    </>
}