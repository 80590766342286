import { BackButton } from "./BackButton"

type TitleProps = {
    /** If true, title has a back button next to it. */
    hasBackButton?: boolean
    /** The url to navigate back to by default when there is no history. */
    defaultHref?: string
    /** Title text. */
    children: React.ReactNode
}

/**
 * Title component for the pages with an optional back button.
 */
export const Title: React.FC<TitleProps> = ({ hasBackButton = true, children, defaultHref }) => {
    return <>
        {hasBackButton && <BackButton defaultHref={defaultHref} />}
        <h1 className="text-2xl md:text-3xl font-semibold mb-2 md:mb-4">{children}</h1>
    </>
}