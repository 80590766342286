import { faCheck, faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik"
import { useNavigate } from "react-router-dom"
import { mixed, object, ObjectSchema, string } from "yup"
import { Button } from "../../components/Buttons/Button"
import { Footer } from "../../components/Footer/Footer"
import { Navbar } from "../../components/Navbar/Navbar"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { avatars, getProfile, setAvatar, setUsername } from "../../services/userProfile"
import { Avatars } from "../../types/profile"
import { PageSeo } from "../../utilities/seo/PageSeo"

type ProfileSettings = {
    name: string
    avatar: Avatars
}

export const Settings: React.FC = () => {
    const profile = getProfile()
    const navigate = useNavigate()

    const initialValues: ProfileSettings = {
        name: profile.name,
        avatar: profile.avatar
    }

    const validationSchema: ObjectSchema<ProfileSettings> = object<ProfileSettings>().shape({
        name: string().required("Name is required.").min(2, "Name must be at least 2 letters long").max(255, "Name can't be longer than 255 letters."),
        avatar: mixed<Avatars>().required("Avatar is required.")
    })

    const onSubmit = (values: ProfileSettings) => {
        setUsername(values.name)
        setAvatar(values.avatar)

        // TODO: Back button problem
        navigate(routes.profile, {
            replace: true
        })
    }

    return <>
        <PageSeo title="Account Settings" description="Change your username and avatars" canonicalLink={routes.settings} />
        <div className="flex flex-col container-fluid h-screen">
            <Navbar buttonTo={routes.games.overview} buttonIcon={faPlay} buttonText="Play" />
            <div className="md:container mb-10">
                <Title defaultHref={routes.profile}>Account Settings</Title>

                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {(props: FormikProps<typeof initialValues>) => (
                        <Form>
                            <div className="mb-4 md:mb-6">
                                <label htmlFor="name" className="block text-gray mb-2">Set your name</label>
                                <Field name="name" className="block bg-white bg-opacity-10 w-full py-2 pl-3 mb-2" placeholder="Name" />
                                <ErrorMessage name="name">{error => <p className="text-red-400 text-sm">{error}</p>}</ErrorMessage>
                            </div>

                            <label htmlFor="avatar" className="block text-gray mb-2">Choose your avatar</label>
                            <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-5 mb-4">
                                {avatars.map(avatar =>
                                    <button type="button" key={avatar} onClick={() => props.setFieldValue("avatar", avatar)} className={`grid first-letter:cursor-pointer bg-white bg-opacity-10 ${props.values.avatar === avatar ? "border-4 border-indigo" : "border-4 border-transparent"}`}>
                                        {props.values.avatar === avatar && <FontAwesomeIcon icon={faCheck} className="row-start-1 row-end-1 col-start-1 col-end-1 ml-auto pr-1 md:pr-2 pt-1" />}
                                        <img src={`/img/avatars/${avatar}.svg`} alt={avatar} className="row-start-1 row-end-1 col-start-1 col-end-1 p-4" />
                                    </button>
                                )}
                            </div>
                            <div className="flex justify-end w-full">
                                <Button type="submit" kind="secondary" icon={faCheck}>Submit</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="mt-auto">
                <Footer />
            </div>
        </div >
    </>
}