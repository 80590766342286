import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

type CardProps = {
    /** Icon to display. */
    icon: IconProp
    /** Title of the card. */
    title: string | number
    /** Description of the card. */
    description: string
    /** Link to redirect to. */
    to: string
}

/**
 * Simple Card component with icon, title and description.
 */
export const Card: React.FC<CardProps> = ({ icon, to, title, description }) => {
    return <div className="relative flex flex-col items-center bg-gray bg-opacity-10 p-10">
        <FontAwesomeIcon className="text-4xl text-violet-500 md:mb-3" icon={icon} />
        <Link className="text-2xl font-semibold text-center md:mb-1 stretched-link" to={to}>{title}</Link>
        <p className="text-center text-gray text-sm md:text-base">{description}</p>
    </div>
}