import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type IngameBlockProps = {
    /** Image to display. */
    image: string
    /** Display name/title for example a Movie Title or Country Name. */
    display: string
    /** Description below name. */
    description?: string
    /** Verb for example "has a",... */
    verb: string
    /** Value title for example "IMDb Rating", "Population",... */
    valueTitle: string
    /** The value we are guessing. */
    value?: JSX.Element | string
    /** Set to true if you want to display the value. */
    shouldDisplayValue?: boolean
    /** Set to true if you want to display the buttons. */
    shouldDisplayButtons?: boolean
    /** Button more function. */
    buttonMoreOnClick?: (values: any) => void
    /** Button less function. */
    buttonLessOnClick?: (values: any) => void
    /** Button more text. */
    buttonLess?: string
    /** Button less text. */
    buttonMore?: string
}

/**
 * The two big blocks in the game that are showing the content.
 */
export const IngameBlock: React.FC<IngameBlockProps> = ({ image, display, verb, description, shouldDisplayButtons = false, value, shouldDisplayValue = false, buttonMore, buttonLess, valueTitle, buttonMoreOnClick = () => "", buttonLessOnClick = () => "" }) => {
    return <>
        <aside className="relative h-full md:min-w-[50%] min-h-[50%] flex flex-col items-center justify-center">
            <div className="absolute block md:hidden bg-no-repeat bg-cover bg-center bg-black bg-opacity-70 bg-blend-darken -z-20 w-full h-full" style={{ backgroundImage: `url(${image})` }}></div>
            <img className="hidden md:block h-40 lg:h-48 w-40 lg:w-48 object-cover rounded-full mb-0 md:mb-10" src={image} alt={display} />
            <h2 className={`text-xl lg:text-3xl 2xl:text-5xl font-semibold text-center mx-20 md:mx-14 ${description ? "mb-1 md:mb-2" : "mb-2 md:mb-6"}`}>{display}</h2>
            {description && <p className="text-gray-300 md:w-1/2 text-center mb-2 md:mb-6">{description}</p>}
            <p className="text-gray text-sm lg:text-lg mb-2 md:mb-3">{verb}</p>
            {shouldDisplayValue &&
                <p className="text-4xl lg:text-5xl 2xl:text-7xl font-semibold mx-3 mb-2 md:mb-3">{value}</p>}
            {shouldDisplayButtons && <div className="flex justify-center md:block w-full md:w-auto px-3 md:px-0">
                <button className="block bg-red hover:bg-red-600 focus:bg-red-700 text-base md:text-xl font-semibold w-[50%] md:w-52 py-2 mb-4" onClick={buttonMoreOnClick}>
                    <FontAwesomeIcon icon={faArrowUp} className="font-bold mr-2" />{buttonMore}
                </button>
                <button className="block -order-1 bg-indigo hover:bg-indigo-600 focus:bg-indigo-700 text-base md:text-xl font-semibold w-[50%] mr-4 md:mr-0 md:w-52 py-2 mb-4" onClick={buttonLessOnClick}>
                    <FontAwesomeIcon icon={faArrowDown} className="font-bold mr-2" />{buttonLess}
                </button>
            </div>}
            <p className="text-gray text-sm lg:text-lg">{valueTitle}</p>
        </aside>
    </>
}