import { routes } from "routes"
import { PageSeo } from "utilities/seo/PageSeo"
import { PageLayout } from "../../components/Layout/PageLayout"
import { Title } from "../../components/Navbar/Title"

/**
 * Language page, under development dont delete cause of seo.
 */
export const Language: React.FC = () => <PageLayout>
    <PageSeo title="Language" description="This page is currently under development." canonicalLink={routes.misc.language} />
    <Title>Language</Title>
    <p>This page is currently under development.</p>
</PageLayout>
