import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { TabProps } from "./Tab"

type TabTagProps = {
    /** The id of the tab, comes from react-horizontal-scrolling-menu package. */
    itemId: string
    /** Is true when tab is selected. */
    selected: boolean
    /** Link to go when you click the tab. */
    to: string
    /** Function to call when you click the tab. */
    onClick: () => void
    /** Tab item with featured tag and tab content. */
    item: {
        props: TabProps
    }
}

export const TabTag: React.FC<TabTagProps> = ({ selected, to, item, onClick }) => {
    return (
        <Link to={to} onClick={onClick} className={`${selected ? "bg-indigo" : "bg-white bg-opacity-20 hover:bg-opacity-30 transition-all"} flex items-center whitespace-nowrap font-semibold text-xs sm:text-sm uppercase rounded-full px-3 sm:px-6 py-2 mr-3 my-2`}>
            <FontAwesomeIcon icon={item.props.featuredTag.icon} className="hidden sm:inline-block pr-2" />
            {item.props.featuredTag.title}
        </Link>
    )
}