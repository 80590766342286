import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties } from "react"
import { Link } from "react-router-dom"

type ButtonProps = {
    /** Which type of button we want. */
    kind?: "primary" | "secondary"
    /** Button type default is "button". */
    type?: "button" | "reset" | "submit"
    /** Optional can add classes to customize margins for example. */
    className?: string
    /** Optional can add style to overwrite width for example. */
    style?: CSSProperties
    /** Function that gets called when you click button. */
    onClick?: () => void
    /** Link to go when you click button. */
    to?: string
    /** Optional icon before the text. */
    icon?: IconProp
    /** When true then the button text disapear mobile and only the icon is shown. */
    shouldHaveSmallIconMobile?: boolean
    /** Will be displayed as button text. */
    children: string
}

/**
 * Button component (with link and button functionality).
 * @example <Button kind="primary" to="/home">Home</Button>
 */
export const Button: React.FC<ButtonProps> = ({ to, onClick, icon, kind = "primary", type, shouldHaveSmallIconMobile = false, className, style, children }) => {
    // Classes
    const buttonBaseClass = `${className} flex items-center justify-center ${kind === "primary" ? "bg-indigo hover:bg-indigo-600 focus:bg-indigo-700" : "bg-red hover:bg-red-600 focus:bg-red-700"} font-semibold ${shouldHaveSmallIconMobile ? "w-10 h-10 md:h-auto rounded-full md:rounded-none md:w-56 md:py-3" : "w-full sm:w-56 py-2 sm:py-3"}`

    // Content 
    const InnerContent = <>
        {icon && <FontAwesomeIcon icon={icon} className={`${shouldHaveSmallIconMobile ? "md:pr-3" : "pr-3"}`} />}
        <span className={`${shouldHaveSmallIconMobile ? "hidden md:inline" : ""}`}>{children}</span>
    </>

    return <>
        {/* Button Link */}
        {to && <Link to={to} style={style} className={buttonBaseClass}>
            {InnerContent}
        </Link>}

        {/* Button onClick */}
        {!to && <button type={type} style={style} onClick={onClick} className={buttonBaseClass}>
            {InnerContent}
        </button>}
    </>
}