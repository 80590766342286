import { patchnotes } from "../pages/Misc/PatchNotes"

const localStorageKey = "mol_version"

export const setUserVersion = () => {
    const knownGameVersion = getKnownGameVersion()
    const currentGameVersion = getCurrentGameVersion()

    if (!knownGameVersion || knownGameVersion !== currentGameVersion)
        saveVersion(currentGameVersion)
}

export const saveVersion = (version: string): void => {
    localStorage.setItem(localStorageKey, version)
}

export const getKnownGameVersion = (): string | null => {
    return localStorage.getItem(localStorageKey)
}

export const getCurrentGameVersion = (): string => {
    return patchnotes[0].version
}

export const hasViewedPatchNotes = (): boolean => {
    const knownGameVersion = getKnownGameVersion()
    const currentGameVersion = getCurrentGameVersion()

    return !(!knownGameVersion || currentGameVersion !== knownGameVersion)
}
