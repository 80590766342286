import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { useEffect } from "react"
import { routes } from "../../routes"
import { Footer } from "../Footer/Footer"
import { Navbar } from "../Navbar/Navbar"

type PageLayoutProps = {
    /** Content of the page */
    children: React.ReactNode
}

/**
 * Page Layout for the misc pages.
 */
export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <div className="container-fluid h-screen flex flex-col">
        <Navbar hasLinks buttonTo={routes.games.overview} buttonIcon={faPlay} buttonText="Play" />
        <div className="md:container mb-10">
            {children}
        </div>

        <div className="mt-auto">
            <Footer />
        </div>
    </div>
}