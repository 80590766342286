import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Link } from "react-router-dom"
import Logo from '../../assets/img/Logo.svg'
import { routes } from "../../routes"
import { Button } from "../Buttons/Button"

type NavbarProps = {
    /** Page to go when you click the cta button. */
    buttonTo: string
    /** Will be displayed as cta button text. */
    buttonText: string
    /** Optional icon before the cta button text. */
    buttonIcon: IconProp
    /** When true then the navbar links are shown. */
    hasLinks?: boolean
}

const navLinks = [{
    to: routes.misc.manual,
    text: "Manual"
}, {
    to: routes.misc.credits,
    text: "Credits"
}, {
    to: routes.misc.feedback,
    text: "Contact us"
}, {
    to: routes.misc.halloffame,
    text: "Hall of fame"
}, {
    to: routes.misc.patchnotes,
    text: "Patch Notes"
}]

/**
 * Display a nav with an cta button and optional links.
 */
export const Navbar: React.FC<NavbarProps> = ({ buttonTo, buttonIcon, buttonText, hasLinks = false }) => {
    const [isNavMobileOpen, setIsNavMobileOpen] = useState<boolean>(false)

    return <nav className="mt-3 md:mt-5">
        <div className="flex items-center justify-between mb-2 md:mb-6">
            <div className="grid">
                <div className="flex items-center row-start-1 row-end-1">
                    {/* Bars Icon */}
                    {hasLinks && <button onClick={() => setIsNavMobileOpen(!isNavMobileOpen)} className="lg:hidden pr-5 py-2">
                        <FontAwesomeIcon icon={faBars} />
                    </button>}

                    {/* Logo */}
                    <Link to={routes.home}>
                        <img src={Logo} alt="logo" className="w-40 h-auto md:w-64" />
                    </Link>
                </div>

                {/* Nav links */}
                {hasLinks && <ul className={`${isNavMobileOpen ? "block" : "hidden"} lg:flex items-center row-start-2 lg:row-start-1 gap-4 ml-3 lg:mx-5 mb-2 lg:mb-0`}>
                    {navLinks.map(navLink => <li key={navLink.text} className="py-2 lg:py-0"><Link to={navLink.to}>{navLink.text}</Link></li>)}
                </ul>}
            </div>

            <div className="self-start">
                {/* Button CTA */}
                <Button to={buttonTo} shouldHaveSmallIconMobile icon={buttonIcon}>{buttonText}</Button>
            </div>
        </div>
    </nav>
}