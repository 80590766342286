import { GameType } from "../types/game"

/**
 * Fetch all games from api.
 * @returns {Promise<Game[]>} - Returns a list of games
 */
export const fetchGames = async (): Promise<GameType[]> => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/en/games.json`)
    return response.json()
}

/**
 * Fetch one single game from api.
 * @returns {Promise<Game>} - Returns a single game
 */
export const fetchSingleGame = async (slug: string): Promise<GameType> => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/en/games/${slug}.json`)
    return response.json()
}