import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

type LinkProps = {
    /** Page to go when you click the link. */
    to: string
    /** Optional icon before the text. */
    icon?: IconProp
    /** When true then the button text disapear mobile and only the icon is shown. */
    external?: boolean
    /** Optional can add classes to customize margins for example */
    className?: string
    /** Will be displayed as link text */
    children: string
}

/**
 * Custom Link, can be external or internal.
 */
export const CustomLink: React.FC<LinkProps> = ({ to, icon, external = false, className, children }) => {
    return external ? <a className={`text-white underline hover:text-gray ${className}`} href={to} target="_blank" rel="noopener noreferrer">
        {icon && <FontAwesomeIcon icon={icon} className={`pr-3`} />}
        {children}
    </a> : <Link to={to} className={`text-white underline hover:text-gray ${className}`}>
        {children}
    </Link>
}