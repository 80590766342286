import { faBook, faComment, faFaceSmileWink } from "@fortawesome/free-solid-svg-icons"
import { Card } from "../../components/Cards/Card"
import { PageLayout } from "../../components/Layout/PageLayout"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { PageSeo } from "../../utilities/seo/PageSeo"

const miscCards = [{
    icon: faBook,
    title: "Manual",
    description: "How to play the game.",
    to: routes.misc.manual
}, {
    icon: faFaceSmileWink,
    title: "Credits",
    description: "Our inspirations.",
    to: routes.misc.credits
}, {
    icon: faComment,
    title: "Contact us",
    description: "Share feedback or talk with us!",
    to: routes.misc.feedback
}]

/**
 * Misc page.
 */
export const Misc: React.FC = () => <PageLayout>
    <PageSeo title="Misc" description="Here, you can choose to check out the manual, view our Credits page, or visit our Contact page to get in touch with us. We value your feedback and appreciate your interest in our game." canonicalLink={routes.misc.overview} />
    <Title>Misc</Title>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {miscCards.map(miscCard => <Card icon={miscCard.icon} title={miscCard.title} description={miscCard.description} to={miscCard.to} />)}
    </div>
</PageLayout>
