import { Helmet } from "react-helmet-async"

type BaseSeoProps = {
    title: string
    description: string
    keywords?: string
    canonicalLink?: string

    ogImage?: string
    ogImageWidth?: string
    ogImageHeight?: string

    twitterImage?: string
}

export const baseTitleSuffix = " | More or Less Game"

export const Seo: React.FC<BaseSeoProps> = ({ title, description, keywords, canonicalLink = "", ogImage = `${process.env.REACT_APP_API_URL}/img/global_og_default.jpg`, ogImageHeight = "630", ogImageWidth = "1200", twitterImage = `${process.env.REACT_APP_API_URL}/img/twitter_default.jpg` }) => (<>
    <Helmet>
        {/** Define per page */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={`${keywords ? `${keywords}, ` : ''}higher or lower, moreorless, more or less game, guessing game, io games, play`} />

        {/** Open Graph */}
        <meta property="og:url" content={`${process.env.REACT_APP_BASE_URL}${canonicalLink}`} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name " content="More or Less Game" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content={ogImageWidth} /> {/** min. Recommend 1200x630 */}
        <meta property="og:image:height" content={ogImageHeight} />
        <meta property="og:image:alt" content="More or Less Game Preview Image" />

        {/** Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@moreorless_io" />
        <meta name="twitter:creator" content="@moreorless_io" />
        <meta name="twitter:image" content={twitterImage} />
    </Helmet>
</>)
