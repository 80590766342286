import { PageLayout } from "../../components/Layout/PageLayout"
import { CustomLink } from "../../components/Links/CustomLink"
import { Title } from "../../components/Navbar/Title"
import { routes } from "../../routes"
import { PageSeo } from "../../utilities/seo/PageSeo"

/**
 * Credits page.
 */
export const Credits: React.FC = () => <PageLayout>
    <PageSeo title="Credits" description="This game is created by a lovely couple who thought to improve the original game Higher or Lower." canonicalLink={routes.misc.credits} />
    <Title>Credits</Title>
    <p className="text-gray mb-2">This game is created by a lovely couple who thought to improve the original game higher or lower.</p>
    <p className="text-gray mb-2">Trophy Icons made by <CustomLink external to="https://www.flaticon.com/authors/vectors-market">Vectors Market</CustomLink> from <CustomLink to="www.flaticon.com"> www.flaticon.com</CustomLink>.</p >
</PageLayout >
